import React from "react";
import ComingSoonNew from "./Components/ComingSoonNew";
function App() {
  return (
    <div className="App">
      <ComingSoonNew />
    </div>
  );
}

export default App;
