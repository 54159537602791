import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as InstagramIcon } from '../assets/instagram.svg';
import { ReactComponent as PhoneIcon } from '../assets/phone.svg';
import { ReactComponent as LinkIcon } from '../assets/link.svg';

import img1 from '../assets/images/grid/1.jpg';
import img2 from '../assets/images/grid/2.jpg';
import img3 from '../assets/images/grid/3.jpg';
import img4 from '../assets/images/grid/4.jpg';
import img5 from '../assets/images/grid/5.jpg';
import img6 from '../assets/images/grid/6.jpg';
import img7 from '../assets/images/grid/7.jpg';
import img8 from '../assets/images/grid/8.jpg';
import img9 from '../assets/images/grid/9.jpg';
import img10 from '../assets/images/grid/10.jpg';
import img11 from '../assets/images/grid/11.jpg';
import img12 from '../assets/images/grid/12.jpg';
import img13 from '../assets/images/grid/13.jpg';
import img14 from '../assets/images/grid/14.jpg';
import img15 from '../assets/images/grid/15.jpg';
import img16 from '../assets/images/grid/16.jpg';
import img17 from '../assets/images/grid/17.jpg';
import img18 from '../assets/images/grid/18.jpg';
import img19 from '../assets/images/grid/19.jpg';
import img20 from '../assets/images/grid/20.jpg';

import logoHorizontal from '../assets/images/logo horizontal.svg';
import logoType from '../assets/images/black logo type.svg';
import logo from '../assets/images/black logo mark.svg';
import logoName from '../assets/images/black name.svg';

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
}

function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
}

function ComingSoon() {
	const images = [
		img1,
		img2,
		img3,
		img4,
		img5,
		img6,
		img7,
		img8,
		img9,
		img10,
		img11,
		img12,
		img13,
		img14,
		img15,
		img16,
		img17,
		img18,
		img19,
		img20,
		img10,
		img20,
		img1,
		img5,
		img11
	];

	console.log(window.innerWidth);

	return (
		<div className='background w-screen h-screen overflow-hidden'>
			<GridContainer
				className='grid grid-cols-3 md:grid-cols-4 gap-4 h-screen'
				cols={window.innerWidth > 768 ? 4 : 3}
			>
				{images.map((img, index) => {
					return (
						<ImageContainer
							className='rounded blur-xs opacity-40'
							cols={window.innerWidth > 768 ? 4 : 3}
							index={index}
						>
							<img
								src={img}
								key={index}
								alt=''
								className='w-full object-fill'
							/>
						</ImageContainer>
					);
				})}
			</GridContainer>
			<div className='flex flex-col items-center absolute top-0 left-0 bottom-0 right-0'>
				<div className='absolute left-1/2 -translate-x-1/2 flex flex-col items-center md:hidden'>
					<img className='w-12 mt-4' src={logo} alt='' />
					<img className='w-60 mt-2' src={logoName} alt='omnipresence' />
				</div>
				<div className='flex flex-row w-screen justify-between items-center px-4 md:px-8'>
					<img
						className='w-10 md:w-64 mt-2 md:mt-8 hidden md:block'
						src={logoHorizontal}
						alt=''
					/>
					<a
						href='tel:+919611555667'
						target='_blank'
						rel='noopener noreferrer'
						className='md:text-neutral-700 transition-transform hover:scale-110 mt-2 md:mt-8 text-xl hidden md:flex'
					>
						<PhoneIcon className='w-6 md:mr-4 text-inherit' />
						<span>+91 9611555667</span>
					</a>
				</div>
				<div className='absolute top-1/2 -translate-y-1/2 text-center flex flex-col items-center'>
					<p className='font-gotham text-3xl md:text-6xl text-center font-GothamBold tracking-widest mb-8'>
						COMING SOON
					</p>
					<p className='text-center font-GothamLight max-w-[75%] md:max-w-xl text-sm md:text-xl tracking-wide mb-12'>
						We're currently working on something fantastic. Meanwhile checkout
						our Social Media outlets below.
					</p>
					<div className='flex justify-center items-center w-3/4 gap-10'>
						{/* Instagram */}
						<a
							href='https://www.instagram.com/omnipresencestudio/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<InstagramIcon className='w-8 mr-4 transition-transform hover:scale-150' />
						</a>
						<a
							href='tel:+919611555667'
							target='_blank'
							rel='noopener noreferrer'
							className='md:hidden'
						>
							<PhoneIcon className='w-8 mr-4 transition-transform hover:scale-150' />
						</a>
						<a
							href='social.omnipresencestudio.com'
							target='_blank'
							rel='noopener noreferrer'
						>
							<LinkIcon className='w-8 mr-4 transition-transform hover:scale-150' />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

const GridContainer = styled.div`
	width: ${({ cols }) => 100 + 100 / (cols - 1)}vw;
`;

const ImageContainer = styled.div`
	transform: translateX(
		${({ cols, index }) => index % (cols * 2) < cols && '-50%'}
	);
`;

export default ComingSoon;
